<template>
  <div
    v-if="dataOptions.input"
    ref="formWrapper"
    class="input-email input-component">
    <v-container>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation>
        <v-layout>
          <v-text-field
            v-model="email"
            required
            type="email"
            placeholder="Digite seu e-mail"
            class="email-field"
            :rules="emailRules"
            @keypress.enter="save" />
          <v-btn
            v-if="isMobile"
            :disabled="!valid"
            color="accent"
            class="btn-mobile-save"
            data-cy="btnSave"
            icon
            @click.native="save">
            <v-icon small>
              mdi-send
            </v-icon>
          </v-btn>
          <v-btn
            v-else
            :disabled="!valid"
            color="accent"
            class="btn-big"
            data-cy="btnSaveEmail"
            @click.native="save">
            Enviar
          </v-btn>
        </v-layout>
      </v-form>
    </v-container>
  </div>
  <message
    v-else
    :data="data" />
</template>

<script>
  import Message from './Message'
  import chatUiMixin from '@/mixins/chat-ui'
  import validate from '@/mixins/validate'

  export default {
    components: { Message },
    mixins: [chatUiMixin, validate],
    data () {
      return {
        email: '',
        valid: true
      }
    },
    computed: {
      dataOptions () {
        return this.data
      }
    },
    mounted () {
      this.startAnimation()
      this.$nextTick(() => {
        document.querySelector('.email-field input') !== null && this.focus()
      })
    },
    methods: {
      save (ev) {
        ev.preventDefault()
        if (this.$refs.form.validate()) {
          let callback = () => {
            let option = {
              id: this.data.id,
              value: this.email
            }
            this.$emit('send', option)
          }
          this.endAnimation(callback)
        }
      },
      focus () {
        document.querySelector('.email-field input').focus()
      }
    }
  }
</script>

<style lang="sass">

</style>
